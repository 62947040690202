@import '../Sass/Sass.scss';

.Foot {
    background-color: $platinum;
    overflow: auto;

    .moto {
        text-align: right;
        padding: rem(10);
        font-family: Louis-Bold-Italic;

        span {
            text-transform: uppercase;
            color: $jet;
            font-size: rem(20);

            &:nth-child(2) {
                color: $gold;
            }
        }

        &:after {
            content: 'TM';
            display: inline-block;
            color: $jet;
            font-size: rem(4);
            padding-bottom: rem(16);
            vertical-align: bottom;
        }
    }

    .copyright {
        text-align: right;
        color: $jet;
        font-size: rem(12);
        padding: rem(10) rem(10) rem(0);
        border-top: 2px solid rgba($jet, .5);
    }
}