@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{font-family:Louis-Bold-Italic;src:url(../../static/media/Louis-BoldItalic.30ffb928.ttf)}@font-face{font-family:Louis-Italic;src:url(../../static/media/Louis-Italic.00153381.ttf)}*{outline:0}html,body{margin:0;padding:0;width:100%;min-height:100%;font-size:4,2666666667vw}@media only screen and (min-width: 1024px){html,body{font-size:.95vw}}.App{font-family:'Open Sans', sans-serif, Arial, Helvetica;font-size:0}

.Head{background-color:#e6e6e6;height:3.125rem;display:flex;justify-content:center;align-items:center}@media only screen and (min-width: 1024px){.Head{padding:0 4.375rem;justify-content:flex-start}}.Head .logo{max-height:80%;padding:0 .625rem}.Head .logo__chev{border-right:1px solid rgba(57,57,57,0.25)}

.Foot{background-color:#e6e6e6;overflow:auto}.Foot .moto{text-align:right;padding:.625rem;font-family:Louis-Bold-Italic}.Foot .moto span{text-transform:uppercase;color:#393939;font-size:1.25rem}.Foot .moto span:nth-child(2){color:#d59700}.Foot .moto:after{content:'TM';display:inline-block;color:#393939;font-size:.25rem;padding-bottom:1rem;vertical-align:bottom}.Foot .copyright{text-align:right;color:#393939;font-size:.75rem;padding:.625rem .625rem 0rem;border-top:2px solid rgba(57,57,57,0.5)}

.Hero{position:relative;background-image:url(../../static/media/m-bg.d6f5203b.png);background-size:cover;height:133.8vw;overflow:auto}@media only screen and (min-width: 1024px){.Hero{background-image:url(../../static/media/d-bg.47f5055f.png);height:48.355vw}}.Hero h1{font-size:2.3125rem;font-family:Louis-Bold-Italic;color:white;margin:1.875rem 1.25rem;width:18.75rem}@media only screen and (min-width: 1024px){.Hero h1{font-size:3.75rem;margin:3.75rem auto;width:46.25rem}.Hero h1 span{display:block;text-align:right}}.Hero:after{content:'';display:block;width:95vw;height:51.7199vw;background-image:url(../../static/media/m-car.fd8d7573.png);background-size:cover;position:absolute;bottom:2.3125rem;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%)}@media only screen and (min-width: 1024px){.Hero:after{background-image:url(../../static/media/d-car.e930cc8b.png);width:84vw;height:23.044vw;bottom:1.875rem}}.Hero.capri{background-image:url(../../static/media/m-capri-bg.d2eb1989.png)}.Hero.capri h1{color:black;margin-right:6rem}.Hero.capri:after{height:58.544vw;bottom:4.875rem;background-image:url(../../static/media/m-capri-car.d538ccc6.png)}@media only screen and (min-width: 1024px){.Hero.capri{background-image:url(../../static/media/d-capri-bg.61a61e4f.png);height:48.355vw}.Hero.capri:after{height:43.544vw;bottom:4.875rem}}.Hero.vessa{height:120.8vw;background-image:url(../../static/media/m-vessa-bg.2c0930fd.webp)}.Hero.vessa h1{color:white;font-size:1.7375rem}.Hero.vessa h1 span.yellow{display:unset;text-align:center;color:#d59700}.Hero.vessa:after{height:26.543vw;bottom:2.875rem;background-image:url(../../static/media/m-vessa-car.a16d93b4.png)}@media only screen and (min-width: 1024px){.Hero.vessa{background-image:url(../../static/media/d-vessa-bg.8642d569.webp);height:48.355vw}.Hero.vessa:after{height:18.5439vw;bottom:4.875rem;background-image:url(../../static/media/d-vessa-car.393c0ebb.webp)}}

.Menu{font-family:Louis-Italic;text-transform:uppercase}@media only screen and (min-width: 1024px){.Menu{position:absolute;top:18.75rem;right:34.375rem;width:16.875rem}.Menu:before{content:'';display:block;position:absolute;top:0;left:.875rem;background-color:#d59700;-webkit-transform:skewX(-30deg);transform:skewX(-30deg);width:100%;height:3rem}}.Menu h2{font-size:.875rem;background-color:#d59700;color:#393939;margin:0;text-align:center;padding:.9375rem}.Menu h2 .vessa-white{color:white}@media only screen and (min-width: 1024px){.Menu h2{background-color:transparent;position:relative;cursor:pointer}.Menu h2:before{content:'';display:block;position:absolute;top:0;right:-4.875rem;background-color:#ffb709;-webkit-transform:skewX(-30deg);transform:skewX(-30deg);width:4.0625rem;height:3rem}.Menu h2:after{content:'';display:block;position:absolute;top:1.5rem;right:-3.1875rem;border-top:.375rem solid #393939;border-left:.375rem solid transparent;border-right:.375rem solid transparent}}.Menu .options{padding:.625rem;background:white}@media only screen and (min-width: 1024px){.Menu .options{overflow:hidden;box-shadow:0 1.25rem 1.25rem rgba(0,0,0,0.5);opacity:0;-webkit-transform:translateY(-50%) scaleY(0);transform:translateY(-50%) scaleY(0);transition:opacity .5s, -webkit-transform .5s;transition:transform .5s, opacity .5s;transition:transform .5s, opacity .5s, -webkit-transform .5s}.Menu .options.open{opacity:1;-webkit-transform:translateY(0) scaleY(1);transform:translateY(0) scaleY(1)}}.Menu .options a{display:block;font-size:.875rem;color:black;margin:0;padding:.625rem;text-decoration:none}@media only screen and (min-width: 1024px){.Menu .options a:hover{text-decoration:underline}}.Menu h2.vessa{margin-left:0px !important}

