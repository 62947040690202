@import '../Sass/Sass.scss';

.Hero {
    position: relative;
    background-image: url(../../images/m-bg.png);
    background-size: cover;
    height: 133.8vw;
    overflow: auto;

    @include media(desktop) {
        background-image: url(../../images/d-bg.png);
        height: 48.355vw;
    }
   
    h1 {
        font-size: rem(37);
        font-family: Louis-Bold-Italic;
        color: white;
        margin: rem(30) rem(20);
        width: rem(300);

        @include media(desktop) {
            font-size: rem(60);
            margin: rem(60) auto;
            width: rem(740);

            span {
                display: block;
                text-align: right;
            }
        }
    }

    &:after {
        content: '';
        display: block;
        width: 100vw / 100 * 95;
        height: 54.442vw / 100 * 95;
        background-image: url(../../images/m-car.png);
        background-size: cover;
        position: absolute;
        bottom: rem(37);
        left: 50%;
        transform: translateX(-50%);

        @include media(desktop) {
            background-image: url(../../images/d-car.png);
            width: 120vw / 100 * 70;
            height: 32.92vw / 100 * 70;
            bottom: rem(30);
        }
    }

    &.capri {
        h1 {
            color: black;
            margin-right: 6rem;
        }

        &:after {
            height: 58.544vw;
            bottom: 4.875rem;
            background-image: url(../../images/m-capri-car.png);
        }

        background-image: url(../../images/m-capri-bg.png);
        
        @include media(desktop) {
            background-image: url(../../images/d-capri-bg.png);
            height: 48.355vw;

            &:after {
                height: 43.544vw;
                bottom: 4.875rem;
            }
        }
    }
    
    &.vessa {
        h1 {
            color: white;
            font-size: 1.7375rem;
            span.yellow {
                display: unset;
                text-align: center;
                color: #d59700;
            }
        }

        &:after {
            height: 26.543vw;
            bottom: 2.875rem;
            background-image: url(../../images/m-vessa-car.png);
        }
        height: 120.8vw;
        background-image: url(../../images/m-vessa-bg.webp);
        
        @include media(desktop) {
            background-image: url(../../images/d-vessa-bg.webp);
            height: 48.355vw;

            &:after {
                height: 18.5439vw;
                bottom: 4.875rem;
                background-image: url(../../images/d-vessa-car.webp);
            }
        }
    }
}