@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

@font-face {
  font-family: Louis-Bold-Italic;
  src: url('./fonts/Louis-BoldItalic.ttf');
  // src: url(./fonts/Louis-BoldItalic.ttf);
}

@font-face {
  font-family: Louis-Italic;
  src: url('./fonts/Louis-Italic.ttf');
  // src: url(./fonts/Louis-Italic.ttf);
}

* {
  outline: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  font-size: 4,2666666667vw;
}

@media only screen and (min-width: 1024px) {
  html,
  body {
    font-size: .95vw;
  }
}

.App {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 0;
}
