@import '../Sass/Sass.scss';

$angle: 30deg;

.Menu {
    font-family: Louis-Italic;
    text-transform: uppercase;

    @include media(desktop) {
        position: absolute;
        top: rem(300);
        right: rem(550);
        width: rem(270);

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: rem(14);
            background-color: $gold;
            transform: skewX(-$angle);
            width: 100%;
            height: rem(48);
        }
    }
   

    h2 {
        font-size: rem(14);
        background-color: $gold;
        color: $jet;
        margin: 0;
        text-align: center;
        padding: rem(15);

        .vessa-white {
            color: white;
        }
        
        @include media(desktop) {
            background-color: transparent;
            position: relative;
            cursor: pointer;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: rem(-78);
                background-color: lighten($gold, 10);
                transform: skewX(-$angle);
                width: rem(65);
                height: rem(48);
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: rem(24);
                right: rem(-51);
                border-top: rem(6) solid $jet;
                border-left: rem(6) solid transparent;
                border-right: rem(6) solid transparent;
            }
        }
    }

    .options {
        padding: rem(10);
        background: white;

        @include media(desktop) {
            overflow: hidden;
            box-shadow: 0 rem(20) rem(20) rgba(black, .5);
            opacity: 0;
            transform: translateY(-50%) scaleY(0);
            transition: transform .5s, opacity .5s;

            &.open {
                opacity: 1;
                transform: translateY(0) scaleY(1);
            }
        }

        a {
            display: block;
            font-size: rem(14);
            color: black;
            margin: 0;
            padding: rem(10);
            text-decoration: none;

            @include media(desktop) {
                &:hover {
                    // background: linear-gradient(white, $platinum);
                    text-decoration: underline;
                }
            }
        }
    }
}
.Menu h2.vessa {
    margin-left: 0px !important;

}