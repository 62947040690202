@import '../Sass/Sass.scss';

.Head {
    background-color: $platinum;
    height: rem(50);
    display: flex;
    justify-content: center;
    align-items: center;

    @include media(desktop) {
        padding: 0 rem(70);
        justify-content: flex-start;
    }

    .logo {
        max-height: 80%;
        padding: 0 rem(10);

        &__chev {
            border-right: 1px solid rgba($jet, .25);
        }
    }
}